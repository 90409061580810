.home-container{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    
}

.home-container .logo{
    width: 15%;
    margin-bottom: 3%;
}

.home-container .logo img{
    width: 100%;
    object-fit: contain;
}

.home-container .login-form{
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.home-container .login-form .input-form{
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1.5% 0;
}

.home-container .login-form .input-form .input-text{
    width: 100%;
    min-height: 35px;
    border: none;
    border-bottom: 1px solid lightgray;
    outline: none;
}

.home-container .login-form .input-form .input-btn{
    width: 100%;
    min-height: 35px;
    border: none;
    background-color: #f0f0f0;
    border-radius: 10px;
    cursor: pointer;
}
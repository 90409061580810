.dropdown-container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dropdown-container .dropdown-title{
    width: 100%;
    font-size: calc(100% - 40%);
    line-height: 1;
    cursor: pointer;
}

.dropdown-list{
    width: 150px;
    height: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 4%;
    color: black;
    background-color: white;
    border: 1px solid #1a73e8;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transition: height 0.2s ease;
}
.collapse-item{
    width: 100%;
    height: 0;
    overflow: hidden;
    border-bottom: none;
    transition: height 0.5s;
    font-size: 1.5rem;
    /* display: flex; */
    /* justify-content: space-around; */
    /* align-items: center; */
}

.collapse-item a{
    color: rgb(26, 115, 232);
    font-size: 1.5rem;
}

.collapse-item .payment-status .payment-complate{
    margin-left: auto;
    display: flex;
    align-items: center;

}
.collapse-item .payment-status .payment-complate .payment-complate-button{
    /* padding: 0 ; */
    font-size: 1.40rem;
    background-color: #ffffff;
    border: 1px solid #000000;
}
.collapse-item .payment-status .payment-complate .payment-complate-button:hover{
    background-color: #c4c4c4;
}
.audience-filter-container{
    width: 100%;
    display: flex;
    justify-content:space-evenly;
    align-items: center;
    border-bottom: 1px solid #c4c4c4;
    line-height: 5;
    padding: 1% 0;
}

.audience-filter-container .audience-filter-item{
    flex: 1;
    width: fit-content;
    margin-right: 1%;
    border-right: 1px solid #c4c4c4;
}
.audience-filter-container .audience-filter-item:first-child{
    flex: 0.25;
    width: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #c4c4c4;

}
.audience-filter-container .audience-filter-item:last-child{
    flex: 1;
    border: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.audience-filter-container .audience-filter-item label{
    margin-right: 1%;
}

.audience-filter-container .audience-filter-item input,
.audience-filter-container .audience-filter-item select{
    border: none;
    outline: none;
    border-bottom: 1px solid #c4c4c4;
    font-size: 1.25rem;
    font-weight: lighter;
}

.audience-filter-container .audience-filter-item button{
    flex: 1;
    color: white;
    background-color: #1a73e8;
    border: none;
    line-height: 5;
    cursor: pointer;
}
.main-layout-container{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
}

/* sidebar */
.main-layout-container .sidebar{
    width: 10%;
    height: 100%;
    min-height: 100%;
    background-color: #051e34;
}

/* logo */
.main-layout-container .sidebar .logo{
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #283549;
}

.main-layout-container .sidebar .logo img{
    width: 60%;
    height: 80%;
    object-fit: contain;
}

/* menu */
.main-layout-container .sidebar .menu.active,.main-layout-container .sidebar .menu:hover {
    background-color: rgba(47, 61, 83);
    transition: background-color 1s;
}

.main-layout-container .sidebar .menu .menu-text{
    padding: 2% 5%;
    cursor: pointer;
    color: white;
    text-decoration: none;
    
}

.main-layout-container .sidebar .menu-item .menu-text {
    line-height: 3;

}


.main-layout-container .sidebar .menu-box .menus{
    height: 0;
    overflow: hidden;
    transition: height 0.35s ease;
}

.main-layout-container .sidebar .menu-box .menus.active{
    height: auto;
    transition: height 0.35s ease;
}

.main-layout-container .sidebar .menu-box{
    width: 100%;
    padding-bottom: 5%;
    display: flex;
    color: white;
    flex-direction: column;
    background-color: #1c2c44;
    border-bottom: 1px solid #1c2c44;
}

.main-layout-container .sidebar .menu-box .menu-title{
    width: 100%;
    line-height: 3;
    font-size: 1rem;
}

.main-layout-container .sidebar .menu-box .menu-item{
    width: 100%;
    line-height: 2;
    font-size: 0.75rem;
}

.main-layout-container .content{
    flex: 1;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.main-layout-container .content .status{
    width: 100%;
    height: 5%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-color: #1a73e8;
    color: white;
}

.main-layout-container .content .status .user-box{
    width: 5%;
    padding: 0 1%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    cursor: pointer;
}

.main-layout-container .content .outlet{
    width: 100%;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto;
}
.login-container{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
}

.login-container .visual{
    /* width: 50%; */
    /* width: 0; */
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 3px 0px 10px -5px rgba(0,0,0,0.7);
}

.login-container .visual img{
    width: 100%;
    height: 100%;
    background: url('Assets/visual.jpeg');
    object-fit: contain;
}

.login-container .content{
    flex: 1;
    height: 100%;
    /* background: url('Assets/visual.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat; */
    display: flex;
    justify-content: center;
    align-items: center;
}
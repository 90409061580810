.device-item-container{
    width: 100%;
    margin: 1% 0;
}

.device-item-container .device-header{
    width: 100%;
    line-height: 3;
    border-bottom: 1px solid #c4c4c4;
    cursor: pointer;
    font-size: 1.25rem;
}

.device-item-container .device-content{
    width: 100%;
    height: 0;
    /* max-height: 0; 변경: height 대신 max-height 사용 */
    border: none;
    overflow: hidden;
    /* transition: max-height 0.2s; 변경: height 대신 max-height에 대한 transition 적용 */
    transition: height 0.2s;
}

.device-item-container .device-content .device-item{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    /* justify-content: space-between;  */
    align-items: center;
    flex-wrap: wrap;
    border-bottom: 1px solid #c4c4c4;
    padding: 1% 0;
    line-height: 2;
}

.device-item-container .device-content .device-item .device-subname{
    width: 100%;
    line-height: 1.5;
    font-size: 1rem;

    /* padding: 1% 0; */
}

.device-item-container .device-content .device-item .device-nm{
    font-size: 1.5rem;
    padding-right: 1%;
}
.device-item-container .device-content .device-item .device-grade{
    width: 100%;
    line-height: 1;
}
.device-item-container .device-content .device-item .device-ssaid{
    font-size: 0.75rem;
    color: #c4c4c4;
}
.device-item-container .device-content .device-item .device-delete{
    margin-left: auto;
}
.device-item-container .device-content .device-item .device-delete .device-delete-button{
    padding: 4px;
    font-size: 1.40rem;
    background-color: #ffffff;
    border: 1px solid #000000;
}
.device-item-container .device-content .device-item .device-delete .device-delete-button:hover{
    background-color: #c4c4c4;
}
.device-item-container .device-content .device-item .device-edit-button input[type="button"]{
    padding: 4px;
    font-size: 1.40rem;
    background-color: #ffffff;
    border: 1px solid #000000;
}
.device-item-container .device-content .device-item .device-edit-button input[type="button"]:hover{
    background-color: #c4c4c4;
    /* border: 1px solid #000000; */
}
.initial-admin-container{
    width: 100%;
    height: 100%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.initial-admin-container .initial-form{
    width: 40%;
    height: 50%;
    border: 1px solid #c4c4c4;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.initial-admin-container .initial-form .input-form{
    width: 90%;
    line-height: 1.5;
    padding: 1.5% 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.initial-admin-container .initial-form .input-form label{
    width: 20%;
    padding: 0 2%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.initial-admin-container .initial-form .input-form input{
    flex: 1;
    line-height: 2;
    padding: 1% 0;
    outline: none;
    border: none;
    border-bottom: 1px solid #c4c4c4;
}

.initial-admin-container .initial-form .input-form select{
    flex: 1;
    line-height: 1.5;
    outline: none;
    border: none;
    border-bottom: 1px solid #c4c4c4;
}

.initial-admin-container .initial-form .input-form input[type=submit]{
    flex: 1;
    outline: none;
    border: none;
    line-height: 3;
    cursor: pointer;
    background-color: #c4c4c4;
    border-radius: 10px;
    color: white;
}
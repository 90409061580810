.theater-register-container{
    width: 95%;
    height: 90%;
}

.theater-register-container .theater-form{
    width: 100%;
    padding: 3% 0;
}

.theater-register-container .theater-form .input-form{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 1%;
}

.theater-register-container .theater-form .input-form label{
    width: 8%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.theater-register-container .theater-form .input-form input{
    flex: 1;
    line-height: 3;
    border: none;
    outline: none;
}

.theater-register-container .theater-form .input-form input.border{
    border-bottom: 1px solid lightgray;
}

.theater-register-container .theater-form .input-form textarea{
    flex: 1;
    border: none;
    padding: 1% 0;
    border-bottom: 1px solid lightgray;
    border-top: 1px solid lightgray;
    outline: none;
}

.theater-register-container .theater-form .input-form input[type=submit]{
    background-color: #1a73e8;
    color: white;
    line-height: 3;
    font-size: 1.25rem;
    cursor: pointer;
    border: none;
}

.theater-register-container .theater-form .input-form .theater-range{
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.theater-register-container .theater-form .input-form .theater-range .text{
    display: flex;
    justify-content: center;
    align-items: center;
}

.theater-register-container .theater-form .input-form .theater-range .text input{
    width: auto;
}

.theater-register-container .theater-form .input-form .theater-range .text{
    width: 5%;
}


.theater-register-container .theater-form .input-form .theater_range_text input{
    width: 50px;
    text-align: center;
    border-bottom: 1px solid lightgray;
}

.theater-register-container .theater-form .input-form .theater_addr{
    cursor: pointer;
}
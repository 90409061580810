.search-container{
    width: 100%;
    height: 90vh;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}

.search-container h1{
    line-height: 3;
}

.search-container .search-box{
    width: 80%;
    border-bottom: 1px solid #c4c4c4;
    display: flex;
    justify-content: center;
    align-items: center;
}

.search-container .search-box input{
    flex: 1;
    line-height: 2;
    font-size: 1.5rem;
    background-color: transparent;
    border: none;
    outline: none;
}

.search-container .search-box input[type=submit]{
    height: 80%;
    font-size: 1.25rem;
    background-color: #c4c4c4;
    flex: 0.1;
    color: white;
    cursor: pointer;
}

.search-container .payment-box{
    width: 80%;
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}

.search-container .payment-box .payment-item{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #c4c4c4;
    cursor: pointer;
}

.search-container .payment-box .payment-item .show-info{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}

.search-container .payment-box .payment-item .show-info .audience-nm{
    font-size: 1.5rem;
    line-height: 2;
}

.search-container .payment-box .payment-item .show-info .audience-nm span{
    font-size: 1.25rem;
}

.search-container .payment-box .payment-item .show-info .audience-tel{
    color: #c4c4c4;
    line-height: 0.5;
}

.search-container .payment-box .payment-item .show-info .payment-title{
    font-size: 1.75rem;
    line-height: 2;
}

.search-container .payment-box .payment-item .payment-info{
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-direction: column;
}

.search-container .payment-box .payment-item .payment-info .payment-status{
    font-size: 1.25rem;
    line-height: 2;
}

.search-container .payment-box .payment-item .payment-info .payment-price{
    font-size: 1.75rem;
    line-height: 2;
}
.collapse-item .payment-status {
    display: flex;
    border-bottom: 1px solid #c4c4c4;
    /* align-items: center; */
}

.collapse-item .payment-status .payment-complate .payment-complate-button{
    /* padding: 0 ; */
    font-size: 1.40rem;
    background-color: #ffffff;
}

@media screen and (max-width: 1440px) {
    .search-container .search-box{
        width: 90%;
    }
    .search-container .payment-box{
        width: 90%;
    }
}
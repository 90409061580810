.audience-item-container{
    width: 100%;
}

.audience-item-container .audience-header{
    width: 100%;
    border-bottom: 1px solid #c4c4c4;
    padding: 1% 0;
}

.audience-item-container .audience-header .audience-date{
    width: 100%;
    /* line-height: 3; */
    font-size: 1.25rem;
    font-weight: lighter;
}

.audience-item-container .audience-header .show-title{
    width: 100%;
    font-size: 1.5rem;
    font-weight: bold;
}

.audience-item-container .audience-header .audience-item-update .audience-nm{
    /* width: 100%; */
    font-size: 1.25rem;
}
.audience-item-container .audience-header .audience-item-update .audience-edit-button{
    margin-left: 15px;
    font-size: 1.25rem;
}

.audience-item-container .audience-header .audience-item-update input[type="button"]{
    width: 40px;
    background-color: #ffffff;
    border: 1px solid #000000;
}
.audience-item-container .audience-header .audience-item-update{
    display: flex;
    /* justify-content: space-between; 좌우 정렬을 위한 스타일 */
    width: 100%;
}

.audience-item-container .audience-header .audience-item-update input[type="button"]:hover{
    background-color: #c4c4c4;
    /* border: 1px solid #000000; */
}
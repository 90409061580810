.theater-item-container{
    width: 100%;
    margin: 1% 0;
}

.theater-item-container .theater-header{
    width: 100%;
    line-height: 3;
    display: flex;
    /* justify-content: flex-start;
    align-items: flex-end; */
    justify-content: space-between;
    align-items: center; /* 우측 중앙 정렬로 변경 */
    /* padding: 1% 0; */
    padding-bottom: 1%;
    border-bottom: 1px solid #c4c4c4;
    cursor: pointer;
}

.theater-item-container .theater-header .theater-thumbnail{
    width: 100px;
    height: 130px;
}

.theater-item-container .theater-header .theater-thumbnail img{
    width: 100%;
    height: 100%;
    object-fit: contain;
    border: 1px solid #c4c4c4;
}

.theater-item-container .theater-header .theater-title{
    flex: 1;
    padding: 0 1%;
}

.theater-item-container .theater-header .theater-title .theater-nm{
    width: 100%;
    font-size: 1.75rem;
    line-height: 1.5;
}

.theater-item-container .theater-header .theater-title .theater-people{
    width: 100%;
    font-size: 0.925rem;
    color: #c4c4c4;
    line-height: 1;
}

.theater-item-container .theater-content{
    width: 100%;
    height: 0;
    overflow: hidden;
    transition: height 0.2s;
}

.theater-item-container .theater-header .theater-button {
    width: 75px;
    height: 75px;
    font-size: 1.40rem;
    background-color: #ffffff;
    border: 1px solid #000000;
    margin-left: 10px;
    cursor: pointer;
  }
.theater-item-container .theater-header .theater-button.del {
    margin-left: 10px;
  }

  .theater-item-container .theater-header .theater-button:hover{
    background-color: #c4c4c4;
}
.show-item-container{
    width: 100%;
}

.show-item-container .show-item-header{
    width: 100%;
    line-height: 3;
    cursor: pointer;
    border-bottom: 1px solid #c4c4c4;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1% 0;
}

.show-item-container .show-item-header .show-thumbnail{
    width: 150px;
    height: 190px;
    border: 1px solid #c4c4c4;
    margin-right: 1%;
}

.show-item-container .show-item-header .show-thumbnail img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.show-item-container .show-item-header .show-info{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}

.show-item-container .show-item-header .show-title{
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 2;
}

.show-item-container .show-item-header .theater-nm{
    line-height: 1;
    font-size: 1.25rem;
    font-weight: lighter;
}

.show-item-container .show-item-header .show-date{
    font-size: 1.25rem;
    font-weight: 1;
}
.show-item-container .show-item-header .show-prepayment{
    line-height: 1.5;
}
.show-item-container .show-item-header .show-price{
    line-height: 1.5;
}

.show-item-container .show-item-content{
    width: 100%;
    height: 0;
    overflow: hidden;
    border: none;
    white-space: pre-wrap;
    transition: height 0.2s;
}
.show-item-container .show-item-header .show-button {
    width: 75px;
    height: 75px;
    font-size: 1.40rem;
    background-color: #ffffff;
    border: 1px solid #000000;
    cursor: pointer;
  }
  .show-item-container .show-item-header .show-button.del {
 margin-left: 10px;
  }

  .show-item-container .show-item-header .show-button:hover{
    background-color: #c4c4c4;
}
.notice-item-container{
    width: 100%;
}

.notice-item-container:last-child{
    padding-bottom: 50px;
}

.notice-item-container .notice-header{
    width: 100%;
    border-bottom: 1px solid lightgray;
    cursor: pointer;
    font-size: 1.5rem;
    line-height: 2;
    padding: 1% 0;
}

.notice-item-container .notice-header .notice-time{
    font-size: 0.75rem;
    color: #c4c4c4;
}

.notice-item-container .notice-content{
    padding: 0px 15px;
    height: 0;
    overflow: hidden;
    white-space: pre-wrap;
    transition: height 0.5s, padding 0.5s;
}

.notice-item-container .notice-header .notice-delete-button{
    width: 40px;
    height: 40px;
    font-size: 1rem;
    background-color: #ffffff;
    margin-left: auto;
    border: 1px solid #000000;
}
.notice-item-container .notice-header .notice-delete-button:hover{
    background-color: #c4c4c4;
}
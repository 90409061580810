.show-register-container{
    width: 95%;
    height: 90%;
}

.show-register-container .show-form{
    width: 100%;
    padding: 3% 0;
}

.show-register-container .show-form .input-form{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 1%;
}

.show-register-container .show-form .input-form label{
    width: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.show-register-container .show-form .input-form input{
    flex: 1;
    line-height: 3;
    border: none;
    outline: none;
}

.show-register-container .show-form .input-form select{
    flex: 1;
    line-height: 3;
    border: none;
    outline: none;
}

.show-register-container .show-form .input-form select.border{
    border-bottom: 1px solid #c4c4c4;
}

.show-register-container .show-form .input-form input.border{
    border-bottom: 1px solid #c4c4c4;
}

.show-register-container .show-form .input-form textarea{
    flex: 1;
    border: none;
    padding: 1% 0;
    border-bottom: 1px solid lightgray;
    border-top: 1px solid lightgray;
    outline: none;
}

.show-register-container .show-form .input-form input[type=submit]{
    background-color: #1a73e8;
    color: white;
    line-height: 3;
    font-size: 1.25rem;
    cursor: pointer;
    border: none;
}
.image-upload-container{
    /* width: 100%; */
    width: 250px;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #c4c4c4;
    cursor: pointer;
}

.image-upload-container .image-uploader{
    width: 100%;
    height: 100%;
}

.image-upload-container img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.payment-item-container{
    width: 100%;
    margin: 1% 0;
}

.payment-item-container .payment-header{
    width: 100%;
    line-height: 1.5;
    border-bottom: 1px solid #c4c4c4;
    /* background-color: antiquewhite; */
    cursor: pointer;
    font-size: 1.25rem;
}

.payment-item-container .payment-content{
    width: 100%;
    height: 0;
    /* max-height: 0; 변경: height 대신 max-height 사용 */
    border: none;
    overflow: hidden;
    /* transition: max-height 0.2s; 변경: height 대신 max-height에 대한 transition 적용 */
    transition: height 0.2s;
}

.payment-item-container .payment-content .payment-item{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    /* justify-content: space-between;  */
    align-items: center;
    flex-wrap: wrap;
    border-bottom: 1px solid #c4c4c4;
    padding: 1% 0;
    line-height: 2;
}
.payment-item-container .payment-content .payment-item .payment-audience_title{
    width: 100%;
    font-size: 1.2rem;
    line-height: 1.5;
    color :#1a73e8
    /* margin: 0; */
}

.payment-item-container .payment-content .payment-item .payment-nm{
    font-size: 1.2rem;
    padding-right: 1%;
}

.payment-item-container .payment-content .payment-item .payment-subname{
    width: 100%;
    line-height: 1.5;
    font-size: 1rem;
}
.payment-item-container .payment-content .payment-item .payment-grade{
    width: 100%;
    line-height: 1.5;
}
.payment-item-container .payment-content .payment-item .payment-ssaid{
    font-size: 0.75rem;
    color: #c4c4c4;
}
.payment-item-container .payment-content .payment-item .payment-delete{
    margin-left: auto;
}
.payment-item-container .payment-content .payment-item .payment-delete .payment-delete-button{
    /* padding: 0 ; */
    font-size: 1.40rem;
    background-color: #ffffff;
    border: 1px solid #000000;

}
.payment-item-container .payment-content .payment-item .payment-delete .payment-delete-button:hover{
    background-color: #c4c4c4;
}

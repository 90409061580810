.notice-register-container{
    width: 95%;
    height: 90%;
}


.notice-register-container .notice-form{
    width: 100%;
    padding: 3% 0;
}

.notice-register-container .notice-form .input-form{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 1%;
}

.notice-register-container .notice-form .input-form label{
    width: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.notice-register-container .notice-form .input-form input{
    flex: 1;
    /* width: 100%; */
    line-height: 3;
    border: none;
    outline: none;
}

.notice-register-container .notice-form .input-form input[type=submit]{
    background-color: #1a73e8;
    color: white;
    line-height: 5;
    cursor: pointer;
}

.notice-register-container .notice-form .input-form textarea{
    flex: 1;
    border: none;
    padding: 1% 0;
    border-bottom: 1px solid lightgray;
    border-top: 1px solid lightgray;
    outline: none;
}
.notfound-container{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}


.notfound-container .notfound-img{
    width: 30%;
    height: 30%;
    margin-bottom: 5%;
}

.notfound-container .notfound-img img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.notfound-container a:link, .notfound-container a:visited{
    color: black;
    text-decoration: none;
}
.enotice-container{
    width: 100%;
    min-height: 100%;
    overflow: auto;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.enotice-container .enotice-item{
    width: 95%;
}